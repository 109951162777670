@each $key, $value in $fontFiles {
  @font-face {
    font-family: map-get($fonts, $key);
    src: url(map-get($value, 'src')) format(map-get($value, 'type'));
    font-weight: normal;
  }
}

:root {
  @each $key, $value in $fontSizes {
    --fs--#{$key}: #{$value};
  }
  @each $key, $value in $fonts {
    --ff--#{$key}: #{$value};
  }
  @each $key, $value in $fontWeights {
    --fw--#{$key}: #{$value};
  }
  @each $key, $value in $lineHeights {
    --lh--#{$key}: #{$value};
  }
}

@each $name, $fontSize in $fontSizes {
  .fs--#{$name}{ font-size: var(--fs--#{$name}); }
}

html {
  font-family: var(--ff--regular);
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	text-rendering: optimizeLegibility;
	-webkit-text-size-adjust: 100%;
	font-family: var(--ff--regular);
	font-size: calc(var(--fs--text) / 1.333);
	font-weight: normal;
	line-height: 1.35;

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--text);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
[class*="heading"] {
  font-weight: var(--fw--bold);
}

small, .small {
  font-size: 0.875em;
}

[class*="heading"] {
  color: var(--color--brand-1);
}

.heading--0, .heading.size--xl {
  font-size: var(--fs--h2);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--h0);
  }
}

.heading--1, h1, .heading.size--l {
  font-size: var(--fs--h3);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--h1);
  }
}

.heading--2, h2, .heading.size--m {
  font-size: var(--fs--h5);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--h2);
  }
}

.heading--3, h3, .heading.size--s {
  font-size: var(--fs--h5);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--h3);
  }
}

.heading--4, h4, .heading.size--xs {
  font-size: var(--fs--h6);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--h4);
  }
}

.fs--inherit {
  font-size: inherit;
}

.p--0, .size--xl {
  font-size: var(--fs--medium);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--xlarge);
  }
}
.p--1, .size--l {
  font-size: var(--fs--xsmall);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--large);
  }
}
.p--2, .size--m {
  font-size: var(--fs--xxsmall);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--medium);
  }
}
.p--3, .size--s {
  font-size: var(--fs--xxsmall);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--small);
  }
}
.p--4, .size--xs {
  font-size: var(--fs--tiny);

  @media screen and (min-width: bp('medium')) {
    font-size: var(--fs--xsmall);
  }
}

.excerpt {
  display: -webkit-box;
  overflow: hidden;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}
