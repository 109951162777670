@import './_mixins';

.row {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: calc(var(--grid--col-gap) * .5);
  padding-top: calc(var(--grid--col-gap) * .5);
  margin-left: calc(var(--grid--col-gap) * -.5);
  margin-right: calc(var(--grid--col-gap) * -.5);
  // width: 100%;

  &--gapless {
    padding: 0;

    &>.col, &>[class*="col-"] {
      padding: 0;
    }
  }

  &--center {
    justify-content: center;
  }
}

.col, [class*="col-"] {
  flex: 1 1 auto;
  padding-left: calc(var(--grid--col-gap) / 2);
  padding-right: calc(var(--grid--col-gap) / 2);
  width: 100%;

  & > * {
    flex: 0 0 auto;
  }
}
[class*="col-"] {
  flex-grow: 1;
}

@for $key from 1 through $columns + 1 {
  @include col($mq: '', $factor: $key);
  @include offset($mq: '', $factor: $key);
}

@media screen and (min-width: map-get($map: $breakpoints, $key: 2)) {
  @for $key from 1 through $columns + 1 {
    @include col($mq: 'sm', $factor: $key);
    @include offset($mq: 'sm', $factor: $key);
  }
}

@media screen and (min-width: map-get($map: $breakpoints, $key: 3)) {
  @for $key from 1 through $columns + 1 {
    @include col($mq: 'md', $factor: $key);
    @include offset($mq: 'md', $factor: $key);
  }
}


@media screen and (min-width: map-get($map: $breakpoints, $key: 5)) {
  @for $key from 1 through $columns + 1 {
    @include col($mq: 'lg', $factor: $key);
    @include offset($mq: 'lg', $factor: $key);
  }
}

@media screen and (min-width: map-get($map: $breakpoints, $key: 7)) {
  @for $key from 1 through $columns + 1 {
    @include col($mq: 'xl', $factor: $key);
    @include offset($mq: 'xl', $factor: $key);
  }
}

@media screen and (min-width: map-get($map: $breakpoints, $key: 8)) {
  @for $key from 1 through $columns + 1 {
    @include col($mq: 'xxl', $factor: $key);
    @include offset($mq: 'xxl', $factor: $key);
  }
}
