:root {
  @each $name, $color in $colors {
    --color--#{$name}: #{$color};
  }
}

@each $name, $color in $colors {
  .fc--#{$name} { color: #{$color}; }
  .bg--#{$name} { background-color: #{$color}; }
  .bc--#{$name} { border-color: #{$color}; }
}

.fc--inherit { color: inherit; }
.bg--current { background-color: currentColor; }
.bc--current { border-color: currentColor; }

html {
}

body {
  color: var(--color--text-dark);
  background: var(--color--neutrals-8);


  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color:  transparent;
}

::selection {
  background-color: var(--color--selection);
  color: var(--color--selection-text);
  mix-blend-mode: difference;
}

a {
  color: inherit;
}
