:root {
  // generate section variables
  @each $key, $value in $sections {
    --section--#{$key}: #{$value};
  }

  --gap: #{$gap};
  // generate gap variables
  @for $i from 0 through $gaps {
    --gap--#{$i}: calc(#{$i} * var(--gap));
  }
  --gap--side: var(--gap--#{$gap--side});
  --gap--default: var(--gap--#{$gap--default});

  // grid variables
  --grid--col-gap: #{$col-gap};
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  @extend .fullhs--min;
}

@for $key from 0 through $gaps {
  .ml--#{$key} { margin-left: var(--gap--#{$key}); }
  .mr--#{$key} { margin-right: var(--gap--#{$key}); }
  .mt--#{$key} { margin-top: var(--gap--#{$key}); }
  .mb--#{$key} { margin-bottom: var(--gap--#{$key}); }

  .-ml--#{$key} { margin-left: calc(var(--gap--#{$key}) * -1); }
  .-mr--#{$key} { margin-right: calc(var(--gap--#{$key}) * -1); }
  .-mt--#{$key} { margin-top: calc(var(--gap--#{$key}) * -1); }
  .-mb--#{$key} { margin-bottom: calc(var(--gap--#{$key}) * -1); }

  .mx--#{$key} { @extend .ml--#{$key}; @extend .mr--#{$key}; }
  .my--#{$key} { @extend .mt--#{$key}; @extend .mb--#{$key}; }
  .m--#{$key} { @extend .mx--#{$key}; @extend .my--#{$key}; }

  .-mx--#{$key} { @extend .-ml--#{$key}; @extend .-mr--#{$key}; }
  .-my--#{$key} { @extend .-mt--#{$key}; @extend .-mb--#{$key}; }
  .-m--#{$key} { @extend .-mx--#{$key}; @extend .-my--#{$key}; }

  .pl--#{$key} { padding-left: var(--gap--#{$key}); }
  .pr--#{$key} { padding-right: var(--gap--#{$key}); }
  .pt--#{$key} { padding-top: var(--gap--#{$key}); }
  .pb--#{$key} { padding-bottom: var(--gap--#{$key}); }

  .px--#{$key} { @extend .pl--#{$key}; @extend .pr--#{$key}; }
  .py--#{$key} { @extend .pt--#{$key}; @extend .pb--#{$key}; }
  .p--#{$key} { @extend .px--#{$key}; @extend .py--#{$key}; }
}
.ml--auto { margin-left: auto; }
.mr--auto { margin-right: auto; }
.mt--auto { margin-top: auto; }
.mb--auto { margin-bottom: auto; }
.mx--auto { @extend .ml--auto; @extend .mr--auto; }
.my--auto { @extend .mt--auto; @extend .mb--auto; }
.m--auto { @extend .mt--auto; @extend .mb--auto; @extend .ml--auto; @extend .mr--auto; }

.section, [class|="section"] {
  display: flex;
  flex-flow: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gap--side);
  padding-right: var(--gap--side);
  width: 100%;
}

@each $key, $value in $sections {
  .section--#{$key} {
    max-width: calc(var(--section--#{$key}) + (2 * var(--gap--side)));

    // @media screen and (min-width: #{$value + $gap--side + $gap--side}) {
    //   padding-left: 0;
    //   padding-right: 0;
    // }
  }
}

:root {
  scroll-behavior: auto !important;
}

.fullh { height: 100%; }
.fullh--min { min-height: 100%; }
.fullw { width: 100%; }

.fullhs { height: 100vh; }
.fullhs--min { min-height: 100vh; }
.fullws { width: 100vw; }
.fullscreen { height: 100vh; width: 100vw; }

.halfhs--min { min-height: 100vh; }

.grow {
  flex-grow: 1;
}

.centered {
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

picture {
  user-select: none;

  img {
    display: block;
    width: 100%;
  }
}

hr {
  border: none;
  display: block;
  height: 1px;
  opacity: .3;
}

svg {
  display: block;
}

table {
  table-layout: fixed;
  width: 100%;
}

p:empty {
  display: none;
}
