@mixin col($mq: '', $factor: 0) {
  @if ($factor != 0) {
    @if ($mq == '') {
      .col--#{$factor} {
        width: calc(#{$factor} / #{$columns} * 100%);
      }
    } @else {
      .col--#{$mq}--#{$factor} {
        width: calc(#{$factor} / #{$columns} * 100%);
      }
    }
  }
}

@mixin offset($mq: '', $factor: 0) {
  @if ($factor != 0) {
    @if ($mq == '') {
      .offset--#{$factor} {
        margin-left: calc(#{$factor} / #{$columns} * 100%);
      }
    } @else {
      .offset--#{$mq}--#{$factor} {
        margin-left: calc(#{$factor} / #{$columns} * 100%);
      }
    }
  }
}
