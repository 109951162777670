.hidden,
[hidden] {
	display: none;
}

.visually-hidden {
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;

	a,
	input,
	button {
		&:focus {
			height: auto;
			position: static;
			width: auto;
		}
	}
}

.unselectable {
	pointer-events: none;
	user-select: none;
}

.mobile {
  @media screen and (min-width: bp('large')) {
    display: none;
  }
}

.desktop {
  @media screen and (max-width: calc(bp('large') - 1px)) {
    display: none;
  }
}
