.social {
    display: flex;
    flex-flow: row nowrap;
    list-style-type: none;
    gap: var(--gap--4);
    justify-content: center;
    padding: 0;

    li {
        a {
            text-decoration: none;
            color: var(--color--neutrals-5);
        }
    }
}