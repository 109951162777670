:root {
  // generate transition variables
  @each $key, $value in $transitions {
    --transition--#{$key}: #{$value};
  }

  @media (prefers-reduced-motion: reduce) {
    @each $key, $value in $transitions {
      // --transition-speed--#{$key}: #{$value} / 4;
      --transition--#{$key}: 0;
    }
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
  1% {
    pointer-events: unset;
    visibility: visible;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    height: auto;
    opacity: 1;
  }
  99% {
    height: auto;
    visibility: visible;
  }
  100% {
    height: 0;
    display: none;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
